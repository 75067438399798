.app-container {
  display: flex;
  flex-direction: column; /* Ajuste para coluna */
  min-height: 100vh; /* Altura mínima para ocupar toda a tela */
}

.section {
  padding: 15px;
  box-sizing: border-box;
}

.left-section {
  flex: 0 0 18%;
  background-color: #fcfcfc; /* Fundo claro para contraste */
}

.right-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: #ffffff; /* Fundo claro */
}

.label-bold {
  font-weight: bold;
  color: #006400; /* Verde escuro para destaque */
}

.form-group {
  margin-bottom: 5px;
  padding: 3px;
  box-sizing: border-box;
  border-radius: 4px;
}

.form-group label {
  display: block;
  margin-bottom: 7px;
  font-weight: bold;
  color: #006400; /* Verde escuro */
}

.form-group input {
  padding: 7px;
  width: 90%;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.form-group input[type="text"] {
  padding: 8px;
  width: 90%;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.form-group select {
  padding: 8px;
  width: 90%;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.form-group input[type="range"] {
  width: 90%;
  height: 8px;
  outline: none;
  opacity: 0.7;
  transition: opacity .2s;
}

input[type="range"]:hover {
  opacity: 1;
}

map { height: 180px; }

input[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 10px;
  background: transparent; /* Tornar a faixa principal transparente */;
  border-radius: 5px;
  outline: none;
  opacity: 0.7;
  transition: opacity .2s;
  border: none;
}

input[type="range"]:hover {
  opacity: 3;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  background: hsl(19, 90%, 52%); /* Amarelo */
  cursor: pointer;
  border-radius: 50%;
  order: none; /* Remover a borda */
  margin-top: -5px; /* Ajustar a posição vertical do thumb */
}

input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 10px;
  background: #04ae00; /* Cor da faixa */
  border-radius: 5px;
  color: transparent;
}

/* Para Firefox */
input[type="range"]::-moz-range-thumb {
  width: 20px;
  height: 20px;
  background: #04ae00; /* Amarelo */
  cursor: pointer;
  border-radius: 50%;
  border: none; /* Remover a borda */
}

input[type="range"]::-moz-range-track {
  width: 100%;
  height: 10px;
  background: #aaa; /* Cor da faixa */
  border-radius: 5px;
}

/* Para Internet Explorer */
input[type="range"]::-ms-thumb {
  width: 20px;
  height: 20px;
  background: #04ae00; /* Amarelo */
  cursor: pointer;
  border-radius: 50%;
}

.result-section {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

@media (max-width: 768px) {
  .app-container {
    flex-direction: column;
  }

  .left-section {
    flex: 1 1 100%;
  }

  .right-section {
    flex: 1 1 100%;
  }
}

@media (min-width: 769px) {
  .app-container {
    flex-direction: row;
  }

  .right-section {
    flex-direction: column;
  }
}

.chart-container {
  flex: 1;
  padding: 10px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.chart {
  height: 90%;
}

.chart h2 {
  margin-bottom: 10px;
  color: #006400; /* Verde escuro */
}

@media (max-width: 768px) {
  .chart-container {
    padding: 10px;
  }
}
